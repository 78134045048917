@charset "UTF-8";
#main__library label {
  width: auto;
}
#main__library #library__tables {
  display: flex;
  margin-top: 30px;
}
#main__library #library__tables > div:first-child {
  border-right: 1px solid lightgray;
  padding-right: 30px;
  margin-right: 30px;
}
#main__library #library__tables table button {
  padding: 0;
  min-width: 30px;
}
#main__library table tr.selected td {
  color: #00CAC0;
}
#main__library table tr.hidden {
  display: none;
}
#main__library table th {
  user-select: none;
  padding: 7px 15px;
  white-space: nowrap;
}
#main__library table th[data-sortable] {
  cursor: pointer;
  border-radius: 4px;
}
#main__library table th[data-sortable]:hover {
  background: rgba(0, 0, 0, 0.1);
}
#main__library table th[data-sortable]:after {
  display: inline-block;
  margin-left: 7px;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;
  content: "▼";
}
#main__library table th[data-direction=asc]:after {
  opacity: 1;
}
#main__library table th[data-direction=desc]:after {
  opacity: 1;
  transform: rotate(180deg);
}
#main__library table td {
  padding-left: 15px;
  padding-right: 15px;
  text-overflow: unset;
}
#main__library table .project__name {
  font-size: 12px;
  color: white;
  padding: 3.5px 7px;
  border-radius: 10px;
  cursor: default;
  user-select: none;
}
#main__library table a.button-link {
  margin: 0;
}

#main__library-link-project #library__projects-list-container {
  height: 100%;
  z-index: 2;
  overflow: hidden;
}
#main__library-link-project #library__projects-list-container #library__projects-list p {
  margin: 0;
  padding: 30px 0;
}
#main__library-link-project #library__projects-list-container #library__projects-list .projects-list__project-item {
  background: white;
  border-radius: 4px;
  padding: 15px;
  margin-right: 7px;
  margin-bottom: 7px;
  cursor: pointer;
  user-select: none;
  width: 250px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}
#main__library-link-project #library__projects-list-container #library__projects-list .projects-list__project-item:hover {
  transform: scale(1.03);
}
#main__library-link-project #library__projects-list-container #library__projects-list .projects-list__project-item.flash {
  transition: unset;
  background: #72f1c5;
}
#main__library-link-project #library__projects-list-container #library__projects-list .projects-list__project-item .project-item__color {
  display: inline-block;
  width: 20px;
  height: 9px;
  border-radius: 4px;
  margin-right: 15px;
}

#main__library-unlink-project #library__projects-list-container {
  height: 100%;
  z-index: 2;
  overflow: hidden;
}
#main__library-unlink-project #library__projects-list-container #library__projects-list p {
  margin: 0;
  padding: 30px 0;
}
#main__library-unlink-project #library__projects-list-container #library__projects-list .projects-to-unlink-list__project-item {
  background: white;
  border-radius: 4px;
  padding: 15px;
  margin-right: 7px;
  margin-bottom: 7px;
  cursor: pointer;
  user-select: none;
  width: 250px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}
#main__library-unlink-project #library__projects-list-container #library__projects-list .projects-to-unlink-list__project-item:hover {
  transform: scale(1.03);
}
#main__library-unlink-project #library__projects-list-container #library__projects-list .projects-to-unlink-list__project-item.flash {
  transition: unset;
  background: #72f1c5;
}
#main__library-unlink-project #library__projects-list-container #library__projects-list .projects-to-unlink-list__project-item .project-item__color {
  display: inline-block;
  width: 20px;
  height: 9px;
  border-radius: 4px;
  margin-right: 15px;
}
